<template>
	<!-- 报检申请 -->
  <div class="mod-config">
	  <el-card body-style="padding-bottom: 0;">
		<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
			<el-row>
				<el-col :span="20">
					<el-form-item label="专业">
						<el-select class="selItemInput" v-model="major" placeholder="请选择">
							<el-option
									v-for="item in majorList"
									:key="item.displayValue"
									:label="item.displayName"
									:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="4" class="selBtnGroup">
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="resetFormData()">重置</el-button>
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="selGetDataList()">检索</el-button>
				</el-col>
			</el-row>
		</el-form>
	  </el-card>
	  <div :style="siteContentViewHeight()">
		  <el-card
				  style="margin-top: 20px; height: 100%;"
				  body-style="height: 100%; padding: 0;">
			  <el-row style="height: 100%;">
				  <el-col :span="2" style="background-color: #e9f4fb; height: 100%;">
					  <el-menu
							  :default-active="tabIndex"
							  class="tabMenu"
							  style="margin-top: 50px;">
						  <el-menu-item :index="index+''" @click="setMajorgory(item.displayValue)" style="text-align: center;" v-for="(item, index) in SubjectDropList">
							  {{item.displayName}}
						  </el-menu-item>
					  </el-menu>
				  </el-col>
				  <el-col :span="22" style="height: 100%;">
					  <el-table
							ref="dataListTable"
							  class="dataListTable"
							  :data="dataList"
							  header-align="center"
							  height="542"
							  :row-class-name="tableRowClassName"
							  @selection-change="handleSelectionChange"
							  style="width: 100%;"
							  header-cell-class-name="stepPointTHeader">
						  <el-table-column
								  type="selection"
								  header-align="center"
								  align="center"
								  width="50">
						  </el-table-column>
						  <el-table-column
								  prop="majorName"
								  label="专业"
								  width="100"
								  align="center">
						  </el-table-column>
						  <el-table-column
							  prop="categoryName"
							  label="范畴"
							  width="100"
							  align="center">
						  </el-table-column>
						  <el-table-column
								  prop="probContext"
								  label="检验项目"
								  width="110"
								  align="center">
						  </el-table-column>
						  <el-table-column
								  prop="testReferences"
								  label="检验内容"
								  align="center">
						  </el-table-column>
						  <el-table-column
								  prop="testTypeName"
								  label="检验形式"
								  align="center">
						  </el-table-column>
						  <el-table-column
								  prop="testReferences"
								  label="参考文档和标识"
								  align="center">
						  </el-table-column>
						  <el-table-column
								  fixed="right"
								  header-align="center"
								  align="center"
								  width="250"
								  label="操作">
							  		 <template slot-scope="scope">
										<el-button v-preventReClick type="warning" size="small"  @click="dataFormSubmit(scope.row.itemId)">申请</el-button>
									 </template>
						  </el-table-column>
					  </el-table>
				  </el-col>
			  </el-row>
		  </el-card>
	  </div>
  </div>
</template>
<script>
    import AddOrUpdate from './testItem-add-or-update'
	import $common from "@/utils/common.js"
export default {
  data() {
    return {
		form:{},
		tabIndex: "0",
		value:"",
		//标的物
		SubjectDropList: [],
		Subject :"",
		dataList: [],
		addOrUpdateVisible: false,
		checked:"",
		//当前页码
		pageIndex: 1,
		//当前记录数
		pageSize: 5,
		testId: '',
		//总页数
		totalPage: 0,
		workNo: $common.getItem("workNo"),
		majorList:[],
		major: "",
		subjectNo:"NNNN-001",
		Majorgory: 0,
		tableSelVal:[]
    };
  },
  components: {
  },
	mounted() {
		this.getMajorList();
		this.getDataList();
		this.getSubjectDropDown();
	},
  methods: {

	  // 获取数据列表
	  getDataList() {
		  this.$http({
			  url:  this.$store.state.httpUrl + "/business/testitem/list",
			  method: 'get',
			  params: this.$http.adornParams({
				  page: this.pageIndex,
				  major:this.major,
				  subjectNo:this.subjectNo,
				  limit: this.pageSize,
				  workNo: $common.getItem("workNo"),
			  })
		  }).then(({data}) => {
			  if (data && data.resultCode === 200) {
				  this.dataList = data.body.records;
				  this.totalPage = data.body.totalCount;
				  this.testId = data.body.records.testId;
			  } else {
				  this.dataList = []
				  this.totalPage = 0
			  }
		  })
	  },
	  //获取专业
	  getMajorList(){
		  this.$http({
			  url:  this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
			  method: "get",
			  params: {},
		  }).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				  this.majorList = data.body;
			  }
		  });
	  },
	  setMajorgory(value){
		  this.subjectNo = value;
		  this.pageIndex = 1;
		  this.major = "";
		  this.getDataList();
	  },
	  //获取标的物
	  getSubjectDropDown() {
		  this.$http({
			  url:  this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
			  method: "get",
			  params: {},
		  }).then(({data}) => {
			  if (data && data.resultCode === 200) {
				  this.SubjectDropList = data.body;
				  this.subjectNo = this.SubjectDropList[0].displayValue;
				  this.getDataList();
			  }
		  })
	  },
	  // 表单申请
	  dataFormSubmit(id) {
		  this.$http({
			  url:  this.$store.state.httpUrl +`/business/testitem/saveTestId`,
			  method: "post",
			  data: this.$http.adornData({
				  itemId: id
			  })
		  }).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				  this.$message({
					  message: "申请成功",
					  type: 'success',
					  duration: 1500,
				  });
			  }
		  });
	  },

	  siteContentViewHeight () {
		  var height = this.$store.state.common.documentClientHeight - 200;
		  return { height: height + 'px' }
	  },
	  handleSelectionChange(val) {
		  this.tableSelVal = val;
	  },
	  tableRowClassName({ row, rowIndex }) {
		  let color = ''
		  for(let item of this.tableSelVal){
			  if(item.itemId === row.itemId)color = 'table-SelectedRow-bgcolor'
		  }
		  return color
	  },
	resetFormData(){
		this.major = "";
	},
	selGetDataList(){
		this.pageIndex = 1;
		this.getDataList();
	},
  }
};
</script>
<style lang="scss" scoped="scoped">
	.selItemText{
		font-size: 14px;
		color: #1c1c1c;
		display: inline-block;
		width: 80px;
	}
</style>
